/* Header style */
.header {
  	display: flex;
  	align-items: center;
  	position: fixed;
  	top: 0;
  	background-color: #fff;
  	width: 100%;
  	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  	z-index: 1000;
}

.header_container {
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	height: 80px;
  	width: 1199px;
  	margin-left: auto;
  	margin-right: auto;
}

.logo > img {
  	height: 40px;
}

.nav_links {
  	display: flex;
  	gap: 40px;
  	margin-right: 40px;
}

.all_section {
  	color: #2D2D2D;
  	text-decoration: none;
  	font-weight: 500;
  	font-size: 14px;
}

.nav_links > a:hover {
  	color: #1C88EC;
}

.current_section {
  	color: #1C88EC;
}

.nav_links_button {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
}

.cta_button > a > button {
  	padding: 15px 45px;
    border-radius: 25px;
    color: #fff;
    background-color: #1C88EC;
    cursor: pointer;
    border: none;
}

.cta_button > a > button:hover {
  	background-color: #1C88EC;
}

.menu-icon {
    display: none;
}

/* Banner style */
.home_background {
    background-image: -webkit-image-set(
        url("./../../public/bg-image.webp") type("image/avif"),
        url("./../../public/bg-image.avif") type("image/avif"),
        url("./../../public/bg-image.jpg") type("image/jpeg"));
    background-image: image-set(
        url("./../../public/bg-image.webp") type("image/avif"),
        url("./../../public/bg-image.avif") type("image/avif"),
        url("./../../public/bg-image.jpg") type("image/jpeg"));
    /* background-image: url('/public/bg-image.png'); */
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 60px;
}

.home {
    width: 1199px;
    display: flex;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-left: auto;
    margin-right: auto;
}

.home_title {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-left: 0px;
    color: #fff;
}

.home_title > p {
  	line-height: 24px;
}

.button_contact_us > a > button {
    padding: 15px 45px;
    border-radius: 25px;
    margin-top: 50px;
    color: #fff;
    background-color: #1C88EC;
    cursor: pointer;
    border: none;
}

/* What we do style */
.what_we_do {
    width: 1199px;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 100px;
}

.content > h1 {
    margin-top: 10px;
    font-size: 2em;
    font-weight: bold;
}
  
.content > p {
    margin-top: 20px;
    font-size: 1.2em;
    line-height: 1.5;
}

.content_image {
    width: 50%;
}

.content_image > img, .content_image > picture > source, .content_image > picture > img {
    max-width: 100%;
    max-height: 100%;
}

.content_description {
    width: 45%;
}

.content_description > h5 {
    padding-bottom: 5px;
    padding-right: 25px;
    color: #1C88EC;
    border-bottom: 2px solid;
    border-color: #1C88EC;
    width: max-content;
}

.line {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #EAEDF2;
    z-index: -10;
}

.section_title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 55%;
}

.section_title {
    font-weight: 700;
    color: #6E7191;
}

.section_title.active {
    color: #1C88EC;
}
  
.dots {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
}
  
.dot {
    width: 25px;
    height: 25px;
    background-color: #D9DEE5;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 5px solid;
    border-color: #EAEDF2;
}
  
.dot.active {
    background-color: #1C88EC;
    border-color: #CBDAF2;
}

.content_text > ul {
    margin-left: -20px;
}

.content_text > ul > li {
	line-height: 24px;
}

.content_text > p {
	font-size: 16px;
    margin-bottom: -16px;
	line-height: 24px;
}

/* Product style */
.product_container {
    background-color: #EBEEF1;
}

.product_content {
    width: 1199px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.product_description {
    width: 47%;
}

.product_description > p {
    line-height: 24px;
}

.product_description > h5 {
    padding-bottom: 5px;
    padding-right: 25px;
    color: #1C88EC;
    border-bottom: 2px solid;
    border-color: #1C88EC;
    width: max-content;
    margin-top: 0px;
}

.product_image {
    width: 47%;
}

.product_image > img, .product_image > picture > source, .product_image > picture > img {
    max-width: 100%;
    max-height: 100%;
}

.product_get_demo > a > button {
    padding: 15px 45px;
    border-radius: 25px;
    margin-top: 50px;
    color: #fff;
    background-color: #1C88EC;
    cursor: pointer;
    border: none;
}

.product_get_demo > a {
    text-decoration: none;
}

/* Mission style */
.mission {
    width: 1199px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.mission_image {
    width: 45%;
}

.mission_image > img, .mission_image > picture > source, .mission_image > picture > img {
    max-width: 100%;
    max-height: 100%;
}

.mission_description {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mission_description > h5 {
    padding-bottom: 5px;
    padding-right: 25px;
    color: #1C88EC;
    border-bottom: 2px solid;
    border-color: #1C88EC;
    width: max-content;
    margin-top: 0px;
}

.mission_description_text > p {
    line-height: 24px;
}

.operate_description {
    margin-bottom: 0px;
}

/* Our values style */
.our_values_container {
    background-color: #EBEEF1;
}

.our_values {
    width: 1199px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.our_values_section_title {
    color: #1C88EC;
    margin-bottom: 40px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    color: #1C88EC;
    border-bottom: 2px solid;
    border-color: #1C88EC;
    width: max-content;
}
  
.values_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}
  
.value_card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 30%;
    text-align: center;
    height: 250px;
    border-bottom: 13px solid;
    border-color: #1C88EC;
}

.value_title {
    color: #1C88EC;
    font-size: 16px;
    margin-bottom: 15px;
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 40px;
    font-weight: 600;
}

.value_description {
    color: #4A4A4A;
    font-size: 14px;
    padding-left: 35px;
    padding-right: 35px;
    line-height: 24px;
}

/* Contact us style */
.contact_us_container {
    width: 1199px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.contact_us_content {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
}

.contact_us_content > h5 {
    color: #1C88EC;
    margin-bottom: 0px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    color: #1C88EC;
    border-bottom: 2px solid;
    border-color: #1C88EC;
    width: max-content;
}

.message_us_title {
    margin-top: 50px;
    margin-bottom: 50px;
}

.chat_with_us {
    width: 100%;
    border: 1px solid;
    border-color: #EAEDF2;
    text-align: center;
    border-radius: 10px;
    padding-bottom: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.chat_with_us > img {
    margin-top: 30px;
    width: 50px;
    height: 50px;
}

.chat_with_us > h3 {
    font-weight: 600;
}

.chat_with_us_button > button {
    padding: 15px 45px;
    border-radius: 25px;
    margin-top: 70px;
    color: #fff;
    background-color: #1C88EC;
    cursor: pointer;
    border: none;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.form_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form {
  	width: 100%;
}

.form_right > input, .text_area > input {
    border-radius: 8px;
    border: 1px solid;
    border-color: #EAEDF2;
    height: 50px;
    font-size: 18px;
    padding-left: 20px;
    margin-bottom: 30px;
}

.form_right {
  	width: 47%;
  	display: flex;
  	flex-direction: column;
  	position: relative;
}

.form_right > label, .text_area > label {
  	font-size: 18px;
  	margin-bottom: 10px;
  	color: #2D2D2D;
}

.text_area {
  	display: flex;
  	flex-direction: column;
  	width: 100%;
}

.text_area > textarea {
  	border-radius: 8px;
  	border-color: #EAEDF2;
  	font-size: 18px;
  	padding-left: 20px;
  	padding-top: 15px;
  	padding-bottom: 15px;
  	max-width: 100%;
}

.form_right > input::placeholder, .text_area > input::placeholder, .text_area > textarea::placeholder {
  	font-size: 14px;
}

.success_message {
  	display: flex;
  	flex-direction: row;
  	margin-top: 10px;
  	align-items: center;
}

.icon_succsess {
  	font-size: 35px;
  	color: #1C88EC;
  	text-align: center;
  	margin-right: 5px;
}

.send_message > button {
    padding: 15px 45px;
    border-radius: 25px;
    margin-top: 70px;
    color: #fff;
    background-color: #1C88EC;
    cursor: pointer;
    border: none;
    display: flex;
}

/* Subscribe style */
.subscribe_background {
    background-image: -webkit-image-set(
        url("./../../public/subscribe.webp") type("image/avif"),
        url("./../../public/subscribe.avif") type("image/avif"),
        url("./../../public/subscribe.jpg") type("image/jpeg"));
    background-image: image-set(
        url("./../../public/subscribe.webp") type("image/avif"),
        url("./../../public/subscribe.avif") type("image/avif"),
        url("./../../public/subscribe.jpg") type("image/jpeg"));
  	/* background-image: url('/public/subscribe.png'); */
  	height: 100%;
  	background-position: center;
  	background-repeat: no-repeat;
  	background-size: cover;
  	margin-top: 80px;
}

.subscribe {
    width: 1199px;
    display: flex;
    flex-direction: row;
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.subscribe > h1 {
  	width: 45%;
  	font-size: 40px;
  	color: #fff;
  	font-weight: 500;
}

.subscribe_input_button {
  	width: 40%;
  	position: relative;
}

.subscribe_input_button > input {
    border-radius: 30px;
    border: 1px solid;
    border-color: #EAEDF2;
    height: 60px;
    font-size: 18px;
    width: 100%;
    padding-left: 20px;
}

.subscribe_input_button > input::placeholder {
  	font-size: 16px;
}

.subscribe_input_button > button {
  	position: absolute;
  	top: 7px;
  	right: 7px;
  	border-radius: 25px;
  	height: 46px;
  	padding: 15px 30px;
  	color: #fff;
  	background-color: #1C88EC;
  	cursor: pointer;
  	border: none;
}

/* Footer style */
.footer_container {
  	width: 1199px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
}

.footer_content {
  	display: flex;
  	flex-direction: column;
  	width: 40%;
}

.footer_content > img {
  	width: 150px;
}

.footer_content > p {
  	margin-top: 50px;
  	margin-bottom: 30px;
  	line-height: 24px;
}

.social_media {
  	display: flex;
  	flex-direction: row;
}

.social_media > a > img {
  	width: 50px;
  	height: 50px;
  	margin-right: 20px;
}

.footer_contact_menu {
  	display: flex;
  	flex-direction: row;
  	width: 40%;
  	justify-content: space-between;
}

.footer_contact_description_location {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
    margin-bottom: 10px;
    margin-top: -3px;
}

.footer_contact_description_location > p {
  	margin-top: 0px;
  	margin-bottom: 0px;
}

.footer_contact_description_email {
  	display: flex;
  	flex-direction: row;
  	align-items: center;
    cursor: pointer;
}

.footer_contact_description_email > a {
    text-decoration: none;
    color: #000;
}

.location {
  	font-size: 24px;
  	padding-right: 5px;
  	padding-top: 4px;
}

.email {
  	font-size: 24px;
  	padding-right: 5px;
}

.footer_contact > h3, .footer_menu > h3 {
  	color: #2D2D2D;
    margin-bottom: 30px;
}

.footer_menu > p {
    cursor: pointer;
}

/* Copyright style */
.copyright_container {
  	border-top: 1px solid;
  	border-color: #EAEDF2;
  	margin-left: 50px;
  	margin-right: 50px;
  	text-align: center;
  	margin-bottom: 40px;
}

.copyright_container > h2 {
  	font-weight: 400;
  	font-size: 18px;
  	color: gray;
}

@media screen and (max-width: 550px) {
	.home_title, .content, .content_text > p, .product_content, .product_description > p,
    .mission, .our_values_container, .contact_us_content, .form_right > input, .text_area > input,
    .form_right > label, .text_area > label, .text_area > textarea, .subscribe, .subscribe_input_button > input,
    .footer_container, .copyright_container > h2 {
		font-size: 14px;
	}

	.section_title_container, .form_right > input::placeholder, .text_area > input::placeholder, .text_area > textarea::placeholder,
    .subscribe_input_button > input::placeholder {
		font-size: 12px;
	}

	.content_image, .product_image, .mission_image {
		width: 100%;
		margin-bottom: 25px;
	}

    .form_container {
        flex-direction: column;
    }

    .form_right {
        width: 100%;
    }

    .icon_succsess {
        font-size: 25px;
    }

    .subscribe > h1 {
        width: 100% !important;
    }
  
    .subscribe_input_button {
        width: 100% !important;
    }

    .footer_content > img {
        width: 120px;
    }

    .footer_content > p {
        margin-top: 25px;
    }

    .social_media > a > img {
        width: 40px;
        height: 40px;
    }

    .footer_contact_description_location {
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 650px) {
    .value_card {
        max-width: 100%;
    }

    .contact_us_content > h1 {
        width: 85% !important;
        text-align: center;
    }
}

@media screen and (max-width: 750px) {
    .content {
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 40px;
		margin-top: 50px;
	}

    .content_description, .product_description {
		width: 100%;
	}

    .content_image, .product_image {
        margin-left: auto;
        margin-right: auto;
	}

    .product_content {
		flex-direction: column-reverse;
		justify-content: space-between;
		margin-bottom: 40px;
		margin-top: 50px;
	}

    .chat_with_us_button > button {
        margin-top: 30px;
    }

    .message_us_title {
        margin-top: 25px;
        margin-bottom: 40px;
    }

    .subscribe {
        flex-direction: column;
    }

    .subscribe {
        align-items: start;
    }

    .footer_container {
        flex-direction: column;
    }

    .footer_content {
        width: 100%;
    }

    .footer_contact_menu {
        width: 100% !important;
        margin-top: 50px;
    }

    .footer_contact, .footer_menu {
        width: 47%;
    }

    .copyright_container {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 970px) {
	.home_title {
		width: 100%;
		padding-right: 0px;
	}

	.section_title_container {
		width: 100%;
	}

	.dots {
		width: 90%;
	}

    .product_get_demo > a > button {
        margin-top: 30px;
    }

    .mission {
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 40px;
		margin-top: 50px;
	}

    .mission_description {
		width: 100%;
	}

    .mission_image {
        margin-left: auto;
        margin-right: auto;
	}

    .contact_us_content {
        width: 100%;
    }

    .send_message > button {
        margin-top: 30px;
    }

    .subscribe > h1 {
        width: 47%;
    }
  
    .subscribe_input_button {
        width: 48%;
    }

    .footer_contact_menu {
        width: 50%;
    }

    .menu-icon {
    	display: block;
    	position: absolute;
    	top: 0;
        right: 0;
    	transform: translate(-100%, 60%);
    	font-size: 1.8rem;
    	cursor: pointer;
  	}

    .nav_links_button {
    	display: flex;
    	flex-direction: column;
    	width: 100%;
    	height: 90vh;
    	position: absolute;
    	top: 80px;
    	left: -100%;
    	opacity: 1;
    	transition: all 0.5s ease;
    	padding-right: 0px;
    	grid-gap: 0px;
    	justify-content: center;
	}

  	.nav_links_button.active {
    	background: #F5F5F7;
    	left: 0;
    	opacity: 1;
    	transition: all 0.5s ease;
    	z-index: 1;
    	padding-left: 0px;
    	justify-content: flex-start;
		touch-action: none;
        padding-top: 30px;
  	}

    .nav_links {
        flex-direction: column;
        gap: 50px;
        margin-right: 0px;
        align-items: center;
    }

    .cta_button > a > button {
        margin-top: 30px;
    }
}

@media screen and (min-width: 550px) and (max-width: 750px) {
    .subscribe > h1 {
        width: 80% !important;
    }
  
    .subscribe_input_button {
        width: 64% !important;
    }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
    .values_container {
        gap: 25px;
    }

    .value_card {
        max-width: 47%;
        height: 275px;
    }
}

@media screen and (min-width: 750px) and (max-width: 1000px) {
    .value_card {
        max-width: 42%;
        height: 250px;
    }
}

@media screen and (min-width: 970px) and (max-width: 1200px) {

}

@media screen and (max-width: 1250px) {
    .header_container {
        width: 100%;
        padding: 30px;
    }

    .home {
        width: 100%;
        flex-direction: column;
        padding: 30px;
        padding-bottom: 50px;
    }

	.what_we_do {
		width: 100%;
		padding: 50px 30px;
		align-items: center;
	}

    .product_content {
        width: 100%;
        padding: 50px 30px;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .mission {
        width: 100%;
        padding: 50px 30px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .our_values {
        width: 100%;
        padding: 30px;
        padding-bottom: 50px;
    }

    .contact_us_container {
        width: 100%;
        padding: 30px;
        padding-bottom: 50px;
    }

    .subscribe_background {
        margin-top: 0px;
    }

    .subscribe {
        width: 100%;
        padding: 30px;
        padding-bottom: 70px;
    }

    .footer_container {
        width: 100%;
        padding: 50px 30px;
    }
}